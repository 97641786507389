* {
  box-sizing: border-box;
}

.coinStartPosition {
  display: flex;
  align-items: center;
  position: 'relative';
}
.diceCoinStartPosition {
  width: 100px;
  }


.coin-animation {
  position: fixed;
  z-index: 5;
  animation-delay: 2.9s;
  animation: fly 1.1s forwards cubic-bezier(0.39, 0.82, 0.84, 0.8);
}

.coin-animationViewMobile {
  position: fixed;
  z-index: 5;
  animation-delay: 3s;
  animation: flyViewMobile 1s forwards cubic-bezier(0.39, 0.82, 0.84, 0.8);
}

.coin {
  width: 26px;
  height: 26px;
  z-index: 310;
  position:relative
}


@media (max-width: 500px) {
  .coin {
    width: 15px;
    height: 15px;
  }
}

@media (min-width: 320px) and (orientation: portrait) {
  .coin-animation > img {
    width: 25px;
    height: 25px;
  }
}

@media (min-width: 600px) and (orientation: portrait) {
  .coin-animation > img {
    width: 33px;
    height: 33px;
  }
}

@keyframes fly {
  0% {
    top: var(--top-from);
    left: var(--left-from);
    opacity: 1;
    transform: scale(1.4);
  }
  25% {
    opacity: 1;
    transform: scale(1.4);
  }
  75% {
    opacity: 1;
    transform: scale(1.4);
  }
  100% {
    top: var(--top-to);
    left: var(--left-to);
    opacity: 0;
    transform: scale(1.4);
  }
}

@keyframes flyViewMobile {
  0% {
    top: var(--top-from);
    left: var(--left-from);
    opacity: 1;
    transform: scale(0.9);
  }
  25% {
    opacity: 1;
    transform: scale(1);
  }
  75% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    top: var(--top-to);
    left: var(--left-to);
    opacity: 0;
    transform: scale(0.9);
  }
}

.pot-animation {
  opacity: 1;
  animation: potAnimation 0.3s ease forwards;
  animation-delay: 1.5s;
}

@keyframes potAnimation {
  from {
    opacity: 1;
    transform: scale(1);
    visibility: visible;
  }
  to {
    opacity: 0;
    transform: scale(0.1);
    visibility: hidden;
  }
}

.mobilepot-animation {
  display: flex;
  align-items: center;
  opacity: 1;
}


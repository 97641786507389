
aside.emoji-picker-react {
  /* background: #111427 ;
  box-shadow: none;
  border: none; */
  height: 100%;
  margin-top: -40px;
  /* -webkit-box-shadow: 0px -15px 8px -7px #0d0c3aab inset;
  -moz-box-shadow: 0px -15px 8px -7px rgba(0,0,0,0), rgba(0,0,0,1)) inset;
  box-shadow: 0px -15px 8px -7px rgba(0,0,0,0), rgba(0,0,0,1))inset; */
  /* -webkit-mask-image: linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,1)), linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
  -webkit-mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: left top, left bottom; */
}

.emoji-categories {
  display: none !important;
}

.active-category-indicator-wrapper{
  display: none;
} 

.emoji-picker-react:nth-last-child(2) {
  display: none;
}

.emoji-picker-react .emoji-group:before {
  background: rgba(0, 4, 83, 0.142);
  border-radius: 10px;
  height: 40px;
}

.chessBoard {
  padding-top: 4px;
  width: 99vw;
}

.container {
  position: relative;
  width: fit-content;
  margin: 0 auto;
}

.dices {
  width: fit-content;
  margin: 0 auto;
}

.dice {
  padding: 8px;
}

.competitorName {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  padding-top: 6px;
  padding-left: -2px;
  line-height: 18px;

  color: #ffc327;
}

.clock {
  position: absolute;
  left: 32px;
  top: 7px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  /* Chess */
  color: #edf4ff;
}

.pieceCube {
  width: 54px;
  height: 54px;
  background: linear-gradient(180deg, #dfe8f7 0%, #edf4ff 100%);
  border-radius: 12px;
  display: flex;
  margin: 0 auto;
  margin-top: 8px;
  align-items: center;
}

.pieceCubeInit {
  display: flex;
  align-items: center;
  margin: 4px;
}

.pieceCubeInit > svg {
  margin: 0 auto;
  display: block;
}

.pieceCube > svg {
  margin: 0 auto;
  display: block;
}

.dicePiece{
  transition: transform 0.5s;
}
.activePiece {
  transform: scale(1);
}

.nonActivePiece {
  /* opacity: 0.3; */
  transform: scale(0.95);
  filter: brightness(0.3);
}

.diceDisabled {
  opacity: 0.2;
  margin: 0 auto;
}

.doubledDisabled {
  display: none;
}

.diceDisabled > div {
  opacity: 0.2;
}


.diceCircle {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  background: linear-gradient(43.94deg, #298671 19.8%, #2AE7BD 75.46%);
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25), 0px 0px 8px #298671;
  border-radius: 100px;
}

.diceCircle > svg {
  position: relative;
  margin: 0 auto;
  padding-top: 16px;
}

.diceCircleDisabled > svg {
  position: relative;
  margin: 0 auto;
  padding-top: 16px;
}

.diceCircleDisabled {
  opacity: 0.7;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  background: linear-gradient(0deg, rgba(41,134,113,1) 0%, rgba(42,231,189,1) 100%);
  border: 1px solid #88ea66;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25), 0px 0px 8px rgba(41,134,113,1);
  border-radius: 100px;
}



.x2Button {
  width: 70px;
  height: 70px;
  margin: 0 auto;
  text-align: center;
  padding-top: 14px;
  font-weight: bolder;
  color: white;
  font-size: 32px;
  background: linear-gradient(180deg, #838ef2 0%, #7469b8 100%);
  border: 1px solid #83a2f0;
  box-sizing: border-box;
  box-shadow: 0px 0px 8px #7e73ff;
  border-radius: 80px;
}

.debugMessage {
  font-size: 12px;
  margin: 0 auto;
  width: fit-content;
  position: relative;
  color: white;
  height: 20px;
}

.timer {
  color: white;
  opacity: 1;
  position: fixed;
  width: 100%;
  top: 200px;
  margin-left: auto;
  text-align: center;
  z-index: 500;
  font-size: 200px;
  font-weight: bolder;
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 1);
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
}

.onlinePoint {
  background: green;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  float: left;
  margin-top: 6px;
  margin-right: 4px;
}

.offlinePoint {
  background: red;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  float: left;
  margin-top: 6px;
  margin-right: 4px;
}

.progressLayout {
  height: 100vh;
  width: 100%;
  padding-top: 50%;
}

.promo {
    position: relative;
    background: linear-gradient(96.93deg, rgba(135, 144, 191, 0.63) 17.9%, rgba(135, 144, 191, 0.09) 105%);
    backdrop-filter: blur(10px);
    border-radius: 16px;
    padding: 16px 24px;
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    width: auto;
    min-height: 80px;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    &::before {
        position: absolute;
        content: '';
        width: 77px;
        height: 70px;
        background: url("./images/icon.svg") no-repeat;
        background-size: 77px 70px;
        top: -12px;
        right: 0;
    }

    @media screen and (min-width: 1200px) {
        padding: 24px 28px;
        font-size: 20px;

        &::before {
            width: 87px;
            height: 79px;
            background-size: 87px 79px;
        }
    }
}

.wrap {
    display: flex;
    flex-wrap: wrap;;
}

.input {
    display: none;
}

.checkbox {
    position: relative;
    display: inline-block;
    width: 1vw;
    height: 1vw;
    cursor: pointer;
    border: 1px solid #00BA6C;

    @media (max-width: 1100px) {
        width: 1.5vw;
        height: 1.5vw;
    }

    @media (max-width: 960px) {
        width: 2vw;
        height: 2vw;
    }

    @media (max-width: 768px) {
        width: 2vw;
        height: 2vw;
    }

    @media (max-width: 580px) {
        width: 3vw;
        height: 3vw;
    }

    @media (max-width: 480px) {
        width: 4vw;
        height: 4vw;
    }

    @media (max-width: 320px) {
        width: 6vw;
        height: 6vw;
    }
}

.checkbox::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0.4vw;
    height: 0.2vw;
    border: solid #00BA6C;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -80%) rotate(-225deg) scaleY(-1);
    opacity: 0;

    @media (max-width: 1100px) {
        width: 0.8vw;
        height: 0.4vw;
        border-width: 0 2px 2px 0;
    }

    @media (max-width: 960px) {
        width: 1vw;
        height: 0.5vw;
        border-width: 0 2px 2px 0;
    }

    @media (max-width: 768px) {
        width: 1vw;
        height: 0.5vw;
        border-width: 0 2px 2px 0;
    }

    @media (max-width: 580px) {
        width: 1.5vw;
        height: 0.8vw;
        border-width: 0 2px 2px 0;
    }

    @media (max-width: 480px) {
        width: 2vw;
        height: 1vw;
        border-width: 0 2px 2px 0;
    }

    @media (max-width: 320px) {
        width: 3vw;
        height: 1vw;
        border-width: 0 2px 2px 0;
    }
}

.input:checked + .checkbox::before {
    opacity: 1;
}

.input:checked + .checkbox {
    background: transparent; 
}

